import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, Divider, TextField } from '@material-ui/core';
import { Grid as VirtualScrollGrid } from 'react-virtualized';
import RenderFieldComponent from '../RenderFieldComponent';
import { convertExcelToJSON } from '../../../Services/OnboardingService';

const TableFieldComponent = ({ path, packageType, field, disabled }) => {
  const { setFieldValue, values } = useFormikContext();
  const [uploading, setUploading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const fieldValue = values.settings[packageType][field.fieldName] || [];
  const addRow = () => {
    const defaultObject = {};
    field.fieldOptions.fields.forEach(subField => {
      defaultObject[subField.fieldName] = '';
    });

    setFieldValue('settings', {
      ...values.settings,
      [packageType]: { ...values.settings[packageType], [field.fieldName]: [...fieldValue, defaultObject] },
    });
  };

  const deleteRow = removeIndex => {
    setFieldValue('settings', {
      ...values.settings,
      [packageType]: {
        ...values.settings[packageType],
        [field.fieldName]: [
          ...fieldValue.filter((row, index) => {
            return index !== removeIndex;
          }),
        ],
      },
    });
  };

  const handleSearch = event => {
    setSearchText(event.target.value);
  };

  if (field.fieldOptions.fields.length === 0) {
    throw new Error('The table input type should have at least one field');
  }

  const subFieldNames = field.fieldOptions.fields.map(({ fieldName }) => fieldName);

  const handleUpload = async event => {
    setUploading(true);
    if (event.target.files.length === 1) {
      const reader = new FileReader();
      let baseFile;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function() {
        baseFile = reader.result;
      };
      reader.onerror = function(error) {
        console.log('Error: ', error);
      };
      reader.onloadend = async function() {
        const { data: newValues } = await convertExcelToJSON(baseFile);
        const keys = Object.keys(newValues[0] || {});
        if (keys.length === 0 || subFieldNames.filter(x => !keys.includes(x)).length > 0) {
          if (newValues.length > 0) {
            alert(
              `Uploaded file is incorrect, we expect the following fields: ${subFieldNames.join(
                ', ',
              )} but got the following: ${keys.join(', ')}`,
            );
          } else {
            alert(`Uploaded file is incorrect, we could not extract any data`);
          }
          setUploading(false);
          return;
        }

        setFieldValue('settings', {
          ...values.settings,
          [packageType]: {
            ...values.settings[packageType],
            [field.fieldName]: newValues,
          },
        });
      };

      setUploading(false);
    }
  };

  let filteredFieldValue = fieldValue;
  if (searchText) {
    filteredFieldValue = fieldValue.filter(row => {
      return (
        Object.keys(row).filter(
          key => key.substr(0, 1) !== '@' && row[key].toLowerCase().includes(searchText.toLowerCase()),
        ).length > 0
      );
    });
  }

  const cellRenderer = ({ key, rowIndex, style }) => {
    const row = filteredFieldValue[rowIndex];
    const realRowIndex = fieldValue.indexOf(row);

    return (
      <Grid container spacing={2} alignContent="center" alignItems="center" key={key || rowIndex} style={{ ...style }}>
        {field.fieldOptions.fields.map(subField => (
          <Grid item xs={Math.ceil(10 / field.fieldOptions.fields.length)} key={subField.fieldName}>
            <RenderFieldComponent
              disabled={disabled}
              path={`${path}[${realRowIndex}].${subField.fieldName}`}
              packageType={packageType}
              field={subField}
            />
          </Grid>
        ))}
        <Grid item xs={2}>
          <Button
            onClick={() => deleteRow(realRowIndex)}
            variant="contained"
            color="secondary"
            size="small"
            disabled={disabled}
          >
            <FormattedMessage id="button.deleteRow" />
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid key={field.fieldName} item xs={12}>
      <Typography variant="h5">
        <FormattedMessage id={`onboarding.settings.${field.fieldName}`} />
      </Typography>
      <Grid container spacing={2} alignContent="center" alignItems="center">
        <Grid item xs={12}>
          <Button onClick={addRow} disabled={disabled} variant="contained" color="primary" size="small">
            <FormattedMessage id="button.addRow" />
          </Button>
          {field.fieldOptions.uploadPossible && !disabled && (
            <input
              type="file"
              onChange={handleUpload}
              disabled={uploading}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Search"
            name="search"
            onChange={handleSearch}
            value={searchText}
            color="secondary"
          />
        </Grid>
        <Grid item xs={6}>
          {fieldValue.length} rows
          {filteredFieldValue.length !== fieldValue.length ? ` - ${filteredFieldValue.length} filtered rows` : ''}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <VirtualScrollGrid
            cellRenderer={cellRenderer}
            columnCount={1}
            rowCount={filteredFieldValue.length}
            columnWidth={800}
            height={500}
            rowHeight={100}
            width={800}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableFieldComponent;
